import React, { useEffect, useState } from 'react';

import introductionImage from './aa-coporation-logo-for-modal.png';

import './style.scss';
import { isMobile } from 'react-device-detect';

export default function AACorpIntroductionModal({
  aACorpModal,
  setAACorpModal,
}) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [opacityArrow, setOpacityArrow] = useState(false);

  const handleDown = () => {
    const newScrollPosition = scrollPosition + 100;

    document.querySelector('.aacorp-modal__content-intro').scrollTop =
      newScrollPosition;

    setScrollPosition(newScrollPosition);
  };

  const handleUp = () => {
    const newScrollPosition = scrollPosition - 100;

    document.querySelector('.aacorp-modal__content-intro').scrollTop =
      newScrollPosition;

    if (scrollPosition < 0) {
      setScrollPosition(0);
    } else {
      setScrollPosition(newScrollPosition);
    }
  };

  useEffect(() => {
    if (scrollPosition <= 0) {
      setOpacityArrow(false);
    } else {
      setOpacityArrow(true);
    }
  }, [scrollPosition]);

  return (
    <div
      className={`aacorp-modal__wrapper`}
      style={{
        display: `${aACorpModal === 'introduction' ? 'block' : 'none'}`,
      }}
    >
      <div className="aacorp-modal__container">
        <div className="aacorp-modal__box">
          <div className="aacorp-modal__header">
            <div
              className="aacorp-modal__close"
              onClick={() => setAACorpModal('')}
            >
              <button className="aacorp-modal__close-btn">&times;</button>
            </div>
          </div>
          <div className="aacorp-modal__body">
            <div className="aacorp-modal__body-wrapper">
              <div className="aacorp-modal__body-up">
                <div className="aacorp-modal__img">
                  <img src={introductionImage} alt="aa-corp-introduction" />
                </div>
                <div className="aacorp-modal__title">
                  <h3>AA CORPORATION INTRODUCTION</h3>
                </div>
              </div>
              <div className="aacorp-modal__content aacorp-modal__content-intro">
                <h5>Who We Are</h5>
                <p>
                  AA Corporation is a Vietnamese-owned and operated organization
                  that is made up of more than 3,000 dedicated employees. Over
                  the last 30 years, AA Corporation has experienced rapid growth
                  while becoming the leading interior contractor and contract
                  furniture manufacturer in Asia. This continued ascension is
                  due in large part to our professional human resource base who
                  have helped to develop AA Corporation with a continued
                  commitment to excellence in all projects that we undertake. At
                  AA Corporation our team is committed to being a group of
                  creative and ambitious individuals that can add value to our
                  customers, our company and our community.
                </p>
                <h5>Where We Work</h5>
                <p>
                  AA Corporation has provided products and services to projects
                  in more than 40 countries worldwide. Our company was
                  originally established in Ho Chi Minh City, Vietnam in 1989,
                  we have since expanded our operations to include offices in
                  Hanoi, Tay Ninh and other countries such as Thimphu (Bhutan),
                  Yangon (Myanmar), Phnom Penh (Cambodia), Tokyo (Japan) and
                  Singapore. AA Corporation focuses on providing interior
                  fit-out services to projects located in Asia, with a strong
                  focus on Vietnam, Myanmar, Bhutan, Cambodia and Laos. In
                  addition, our factory is currently exporting both custom
                  millwork and loose furniture products to projects throughout
                  the world. AA Corporation’s manufacturing facility is located
                  in Tay Ninh Province, Vietnam with more than 175,000 square
                  meters of indoor production space. Over the last 30 years, AA
                  Corporation has transformed from a startup company providing
                  interior solutions in HCMC, Vietnam to an international
                  organization providing some of the highest quality furniture
                  products to hospitality projects worldwide.
                </p>
                <h5>What We Do</h5>
                <h6>Hospitality Fit-Out</h6>
                <p>
                  AA Corporation is the leading interior fit-out company in Asia
                  with an established reputation for consistent delivery of
                  cutting-edge interior service within the hospitality market.
                  Possessing an expert interior team and extensive fit-out
                  experience, we pride ourselves in providing the highest
                  quality services for the most prestigious hospitality projects
                  in Vietnam and overseas. By consistently achieving a high
                  level of quality in bringing inception to completion and
                  defeating many challenging deadlines, we have built strong
                  relationships and gained the trust of famed designers in the
                  industry. Moreover, our ability in decision-making and strong
                  partnership with our customers, designers and project
                  management team enable us to ensure that every project is
                  completed successfully and exceeds everyone’s expectations.
                </p>
                <h5>Hospitality Furniture</h5>
                <p>
                  To become a one-stop shop for hospitality interior needs, AA
                  Corporation constructed a factory in 1996 and began producing
                  customized furniture. Since then, we have expanded our
                  operation, which now includes three factories spread
                  throughout Vietnam. Over the last two decades, AA factories
                  have grown to become one of the highest-quality manufacturing
                  facilities in Asia. We have also expanded our customer base to
                  North America, Europe, the Middle East and Asia Pacific. Our
                  clients include the world’s top five-star hotel groups such as
                  Starwood, Accor, IHG, Marriott, Hilton, Hyatt and Fairmont.
                  With 175,000 m² of production space and 2300 skilled workers,
                  we can produce a full range of furniture items with the
                  highest standard and finish, exceptional craftsmanship and
                  outstanding sharpness of detail.
                </p>
              </div>
            </div>

            {!isMobile && (
              <>
                <span
                  className="aacorp-modal__arrow aacorp-modal__arrow-up"
                  onClick={handleUp}
                  style={{
                    opacity: `${opacityArrow ? 1 : 0.1}`,
                    pointerEvents: `${opacityArrow ? 'all' : 'none'}`,
                  }}
                >
                  &#x276E;
                </span>
                <span
                  className="aacorp-modal__arrow aacorp-modal__arrow-down"
                  onClick={handleDown}
                >
                  &#x276E;
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
