import { useMemo, useState, useEffect } from 'react';
import { Html } from '@react-three/drei';
import { degToRad } from 'three/src/math/MathUtils';
import { checkVideoUrl } from 'containers/world/utils';
import VimeoPreview from './VideoComponent/VimeoPreview';
import YoutubePreview from './VideoComponent/YoutubePreview';
import { OverlayComponent } from './VideoComponent/OverlayComponent';
import ShapeHover from './ShapeHover';
import { useThree } from '@react-three/fiber';

export function VideoFrame({ position, hs, onClick, setHpName }) {
  const { camera, scene } = useThree();

  const [isHover, setIsHover] = useState(false);

  const videoData = useMemo(() => {
    if (hs.media && hs.configs) {
      return { ...JSON.parse(hs.media.customData), ...JSON.parse(hs.configs) };
    }
    return JSON.parse(hs.media.customData);
  }, [hs.configs, hs.media]);

  const videoType = useMemo(
    () => checkVideoUrl(videoData?.videoUrl),
    [videoData?.videoUrl]
  );

  const videoSize = useMemo(
    () =>
      videoData.height &&
      videoData.width && { height: videoData.height, width: videoData.width },
    [videoData.height, videoData.width]
  );

  const htmlName = useMemo(() => `html-shape ${hs.media?.id}`, [hs]);

  useEffect(() => {
    const object = scene.getObjectByName(htmlName);
    object.lookAt(0, 0, 0);
    if (videoData?.quaternion) {
      object.quaternion.x = videoData.quaternion?.x;
      object.quaternion.y = videoData.quaternion?.y;
      object.quaternion.z = videoData.quaternion?.z;
      object.quaternion.w = videoData.quaternion?.w;
    } else {
      object.rotation.set(
        degToRad(videoData.rotate?.x),
        Math.atan2(
          camera.position.x - position[0],
          camera.position.z - position[2]
        ) + degToRad(videoData.rotate?.y),
        0
      );
    }
  }, [camera.position, scene, position, htmlName, videoData]);

  return (
    <>
      <ShapeHover
        title={hs.media.title}
        isHover={isHover}
        htmlName={htmlName}
      />
      <Html
        transform
        position={position}
        scale={20}
        zIndexRange={[0, 0]}
        name={htmlName}
      >
        <OverlayComponent
          height={videoData.height}
          width={videoData.width}
          onClick={onClick}
          title={hs.media.title}
          setHpName={setHpName}
          isHover={isHover}
          setIsHover={setIsHover}
        />
        {videoType !== 'Youtube' ? (
          <div className="media-preview-video" style={{ ...videoSize }}>
            <VimeoPreview
              videoUrl={videoData?.videoUrl}
              title={hs.friendlyName}
            />
          </div>
        ) : (
          <div className="media-preview-video" style={{ ...videoSize }}>
            <YoutubePreview
              videoUrl={videoData?.videoUrl}
              title={hs.friendlyName}
            />
          </div>
        )}
      </Html>
    </>
  );
}
