import React, { useRef, memo, useEffect, useState, useMemo } from 'react';
// import { gsap } from 'gsap';
// import configs from 'configs';
// import { updateVector3OrPointByRotationXZ } from 'containers/world/utils';
import { BOX_WIDTH } from 'containers/world/constant';
import { useFrame, useThree } from '@react-three/fiber';
// import { TRANSITION_METHOD } from '../transition.const';
import { SIDE } from './constants';
import CrossfadeMaterial from './materials/material';
// import { createTripodMarker } from './tripodMarker';
// import SceneInteractionTracker from './SceneInteractionTracker';
// import { DoubleSide } from 'three';
// import { useVideoTexture } from '@react-three/drei';
import VideoSphere from './VideoSphere';
import { degToRad } from 'three/src/math/MathUtils';

// const SCALE_RANGE = {
//   BIG: 10,
//   NORMAL: 1,
// };

function CubeEffect({
  texture0,
  texture1,
  mixFactor,
  stepHandlers,
  pano,
  getCameraParams,
  getSceneRotation,
}) {
  const meshRef = useRef(null);
  const meshVideoRef = useRef(null);
  const [opacity, setOpacity] = useState(0);
  const boxArgs = useMemo(() => [BOX_WIDTH, BOX_WIDTH, BOX_WIDTH, 1, 1, 1], []);
  // const sceneViewRef = useRef();
  const { camera, raycaster } = useThree();

  // console.log(pano);
  // console.log(meshRef, meshVideoRef);

  window.meshRef = meshRef.current;
  window.meshVideoRef = meshVideoRef.current;

  // useEffect(() => {
  //   meshRef.current.scale.set(
  //     -SCALE_RANGE.NORMAL,
  //     SCALE_RANGE.NORMAL,
  //     SCALE_RANGE.NORMAL
  //   );
  //   meshRef.current.updateMatrix();
  // }, [meshRef]);

  // useEffect(() => {
  //   const sceneId = stepHandlers.sceneData?.id || '';
  //   meshRef.current.name = sceneId;
  // }, [meshRef, stepHandlers]);

  useEffect(() => {
    const { active, rotations } = stepHandlers;
    const [prevRot, currRot] = rotations.slice(-2);
    getSceneRotation({ prevRotation: prevRot, currRotation: currRot });

    setOpacity(active ? 1 : 0);
    // meshRef.current && meshRef.current.scale.set(-0.5, 0.5, 0.5);
    meshRef.current && meshRef.current.scale.set(-0.99, 0.99, 0.99);
    meshRef.current && meshRef.current.updateMatrix();
    meshVideoRef.current && meshVideoRef.current.updateMatrix();
  }, [pano, stepHandlers, getSceneRotation]);

  // useEffect(() => {
  //   const { active, stepPosition, rotations } = stepHandlers;
  //   setOpacity(active ? 1 : 0);
  //   const shouldAnimate =
  //     window.transitionMethod === TRANSITION_METHOD.GROUND_NAV;
  //   const [prevRot, currRot] = rotations.slice(-2);

  //   console.log('active', active, pano);

  //   if (active) {
  //     meshRef.current.rotation.y = currRot?.rad || 0;
  //     meshRef.current.updateMatrix();
  //     // animation to show the scene
  //   }
  // }, [stepHandlers]);

  // const mouseUp = () => {
  //   sceneViewRef.current.handleMouseUp();
  // };

  // const mouseDown = () => {
  //   sceneViewRef.current.handleMouseDown();
  // };

  // const videoUrl = useMemo(
  //   () =>
  //     'https://vizion-video-streaming-staging.azurewebsites.net/video_360_duc_thanh_short.mp4',
  //   []
  // );

  // const texture = useVideoTexture(videoUrl);
  // console.log('🚀 ~ texture:', texture);

  const findCenterPointLookAt = () => {
    // Set the raycaster position based on the center point look at and camera
    raycaster.setFromCamera({ x: 0, y: 0 }, camera);

    // Find the intersection of the raycaster and the box object
    let intersects;
    if (meshRef.current) {
      intersects = raycaster.intersectObject(meshRef.current);
    } else if (meshVideoRef.current) {
      intersects = raycaster.intersectObject(meshVideoRef.current);
    }
    const { x, y, z } = intersects[0].point;
    return [x, y, z];
  };

  useFrame(() => {
    if ((meshRef.current || meshVideoRef.current) && pano) {
      const lookAtPoint = findCenterPointLookAt();
      getCameraParams({ lookAtPoint, fov: camera.fov });
    }
  });

  if (pano?.id === 'packaging-area-2')
    return (
      <mesh
        ref={meshVideoRef}
        scale={[-1, 1, 1]}
        rotation={[0, degToRad(90), 0]}
        matrixAutoUpdate={false}
        renderOrder={3}
        // onPointerUp={mouseUp}
        // onPointerDown={mouseDown}
      >
        {/* <SceneInteractionTracker
          ref={sceneViewRef}
          meshRef={meshRef}
          pano={pano}
        /> */}
        <VideoSphere />
      </mesh>
    );

  return (
    <mesh
      ref={meshRef}
      scale={[-1, 1, 1]}
      matrixAutoUpdate={false}
      renderOrder={2}
      // onPointerUp={mouseUp}
      // onPointerDown={mouseDown}
    >
      {/* <SceneInteractionTracker
        ref={sceneViewRef}
        meshRef={meshRef}
        pano={pano}
      /> */}
      <boxGeometry attach="geometry" args={boxArgs} />
      {SIDE.map((side, index) => (
        <CrossfadeMaterial
          key={side}
          texture1={texture0[side]}
          texture2={texture1[side]}
          mixFactor={mixFactor}
          index={index}
          opacity={opacity}
        />
      ))}
    </mesh>
  );
}

export default memo(CubeEffect);
