import React from 'react';

// import VideoIcon from 'assets/icons/video.png';

const Icon = (props) => {
  return (
    <div className="hotspot_icon">
      <svg
        width="12"
        height="12"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 450 512"
      >
        <path
          d="M112 111v290c0 17.44 17 28.52 31 20.16l247.9-148.37c12.12-7.25 12.12-26.33 0-33.58L143 90.84c-14-8.36-31 2.72-31 20.16z"
          fill="white"
          stroke="white"
          strokeMiterlimit="10"
          strokeWidth="32"
        />
      </svg>
    </div>
  );
};

export default Icon;
