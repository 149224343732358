import React from 'react';

import InfoIcon from 'assets/icons/long-right-arrow.png';

const Icon = (props) => {
  return (
    <div className="hotspot_icon hotspot_icon-hide animate-flicker">
      <div className="hotspot_instruction-right">Ho Chi Minh City (60km)</div>
      <img alt="info-icon" src={InfoIcon} style={{ width: '70px' }}></img>
    </div>
  );
};

export default Icon;
