import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import SizeDetector from './containers/detectors/size.detector';
import BrandColorHandler from 'containers/detectors/brandColor.handler';
import AppRoutes from './AppRoutes';
import TourError from './TourError';

import './App.scss';

document.title = 'Vizion';

function App({ error }) {
  const ContentBody = useMemo(() => {
    if (error !== null) {
      return <TourError data={error} />;
    }
    return <AppRoutes />;
  }, [error]);

  return (
    <div className="App">
      <SizeDetector />
      <BrandColorHandler />
      {ContentBody}
    </div>
  );
}

const mapStateToProps = ({ error }) => ({ error });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(App);
