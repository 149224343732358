import React from 'react';

import { Color } from 'three';
import { connect } from 'react-redux';

import { ensureSubscriptionData } from 'utils';
import { goToScene, setAerialMapData } from 'store/actions';
import Popup from 'components/popup';
import { trackEventByType, EVENT_TYPE } from 'gaTracking';

import { MAP_SIZE } from './box';
import { queryMapScenes } from './bubblePositioning';
import MapBoxUtils from './mapBoxUtils';

import './style.scss';

class TheedMap extends React.Component {
  state = {
    isDragging: false,
    angleZ: -45,
    mapRotate: 0,
    lightSettings: {
      intensity: 0.5,
      color: new Color('white'),
    },
  };
  center = {
    x: 0,
    y: 0,
  };
  oldCoord = {
    x: 0,
    y: 0,
  };
  aerialScenes = [];
  interval = null;

  componentDidMount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.aerialScenes = queryMapScenes(this.props.scenes);
    if (!this.props.aerialDataReady) {
      this.calcAerialScenesPosition();
    }
    if (this.props.mapConfig) {
      this.applyMapConfig(this.props.mapConfig);
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  applyMapConfig(mapConfig) {
    const lightSettings = { ...this.state.lightSettings };
    if (mapConfig.light) {
      if (typeof mapConfig.light.intensity === 'number') {
        lightSettings.intensity += mapConfig.light.intensity;
      }
      if (typeof mapConfig.light.color === 'string') {
        lightSettings.color.set(mapConfig.light.color);
      }
    }
    this.setState({ lightSettings });
  }

  setCenter = ({ x, y, visible }) => {
    this.center = { x, y, visible };
    window.logMessage('this.center', { ...this.center });
  };

  calcAerialScenesPosition = () => {
    const { center, zoom } = this.props.mapConfig;
    const map = new MapBoxUtils({
      width: MAP_SIZE,
      height: MAP_SIZE,
      center,
      zoom,
    });
    this.aerialScenes.forEach((aerial) => {
      var coordinates = [aerial.coordinates[0], aerial.coordinates[1]];
      aerial.mapCoords = map.project(coordinates);
      window.logMessage('aerial.mapCoords', { ...aerial.mapCoords });
    });
    this.props.setAerialMapData(this.aerialScenes);
  };

  onClickBubble = ({ id, groupId }) => {
    window.logMessage('id, groupId', id, groupId);
    this.props.goToScene(groupId, id);
    trackEventByType(EVENT_TYPE.SCENE_FROM_HOTSPOT, id);
  };

  handlePointerDown = (e) => {
    this.oldCoord.x = e.pageX;
    this.oldCoord.y = e.pageY;
    this.setState({ isDragging: true });
  };

  handlePointerUp = () => {
    const newAngleZ = Math.round((this.state.angleZ - 45) / 90) * 90 + 45;
    this.setState({
      isDragging: false,
      angleZ: newAngleZ,
      mapRotate: newAngleZ + 45,
    });
  };

  handlePointerMove = (e) => {
    if (this.state.isDragging) {
      const coord = { x: e.pageX, y: e.pageY };
      const coord1 = this.centralizeCoordinates(
        this.oldCoord.x,
        this.oldCoord.y
      );
      const coord2 = this.centralizeCoordinates(coord.x, coord.y);
      const angle = this.AngleBetweenVectors(
        coord1.x,
        coord1.y,
        coord2.x,
        coord2.y
      );
      this.setState({ angleZ: this.state.angleZ + angle });
      this.oldCoord = {
        ...coord,
      };
    }
  };

  centralizeCoordinates = (x, y) => ({
    x: x - this.center.x,
    y: y - this.center.y,
  });

  AngleBetweenVectors = (Ax, Ay, Bx, By) => {
    const magA = Math.sqrt(Ax * Ax + Ay * Ay);
    const magB = Math.sqrt(Bx * Bx + By * By);
    const magAmagB = magA * magB;
    if (magAmagB === 0) return 0;
    const sinTheta = (Ax * By - Bx * Ay) / magAmagB;
    const cosTheta = (Ax * Bx + Ay * By) / magAmagB;
    const theta = Math.atan2(sinTheta, cosTheta);
    return (theta * 180) / Math.PI;
  };

  render() {
    // const { mapConfig, aerialDataReady, aerialMapData } = this.props;
    // const { angleZ, isDragging, lightSettings } = this.state;

    return (
      <Popup closePopup={() => false}>
        {/* <div
          className="threed-map-backdrop"
          onPointerDownCapture={this.handlePointerDown}
          onPointerMoveCapture={this.handlePointerMove}
          onPointerUpCapture={this.handlePointerUp}
        >
          {aerialDataReady ? (
            <Canvas camera={{ fov: 50 }}>
              <ambientLight
                intensity={lightSettings.intensity}
                color={lightSettings.color}
              />
              <Suspense fallback={null}>
                <Box
                  mapConfig={mapConfig}
                  rotateDeg={angleZ}
                  tour={this.props.tour}
                  isDragging={isDragging}
                  updateCenter={this.setCenter}
                  aerialScenes={aerialMapData}
                  onClickBubble={this.onClickBubble}
                  hotspotHistory={this.props.hotspotHistory}
                  scope={this.props.scope}
                  history={this.props.history}
                />
              </Suspense>
            </Canvas>
          ) : (
            <MapLoading />
          )}
        </div> */}
        <div className="map_iframe">
          {this.props.tour.menuStyle === 'aacorporation' ? (
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26751.203760170894!2d106.43480926013817!3d11.155284593354773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x310b31d7bf3892f1%3A0x8e278948858c0465!2sAA%20T%C3%A2y%20Ninh!5e0!3m2!1sen!2s!4v1709525621180!5m2!1sen!2s"
              title="Google Map - AA Tay Ninh"
            ></iframe>
          ) : (
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.0091133628034!2d106.71423757456373!3d11.037942754316548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174d00f4956f035%3A0x79f8cdd3ed35cbd7!2sDuc%20Thanh%20Wood%20Joint%20Stock%20Company!5e0!3m2!1sen!2s!4v1708660491467!5m2!1sen!2s%22"
              title="Google Map - Duc Thanh Wood Joint Stock Company"
            ></iframe>
          )}
        </div>
      </Popup>
    );
  }
}

const mapStateToProps = ({
  json,
  hotspotHistory,
  aerialMapData,
  aerialDataReady,
  tour,
}) => ({
  mapConfig: {
    center: [json.tour.mapCoordinates[0], json.tour.mapCoordinates[1]],
    zoom: json.tour.mapCoordinates[2],
    light: {
      color: json.tour.mapLightColor || 'white',
      intensity: json.tour.mapLightIntensity || 0.5,
    },
    mapBoxAccessToken: json.tour.mapBoxAccessToken,
    mapBoxStyle: json.tour.mapBoxStyle,
  },
  brandColor: json.tour.menuHighlightColor,
  scenes: json.scenes,
  tour: json.tour,
  hotspotHistory,
  aerialMapData,
  aerialDataReady,
  scope: ensureSubscriptionData(tour.subscriptionPlan.scopes),
});

const mapDispatchToProps = {
  goToScene,
  setAerialMapData,
};

export default connect(mapStateToProps, mapDispatchToProps)(TheedMap);
