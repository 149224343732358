import React, { useState } from 'react';
import CIcon from '@coreui/icons-react';
import { cilX } from '@coreui/icons';
import { useTranslation } from 'react-i18next';

import './style.scss';

const PopupScenePasscode = (props) => {
  const { t } = useTranslation();
  const { onClose, scenePasscode, onSelect } = props;
  const [passcode, setPasscode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handlePasscodeChange = (event) => {
    setPasscode(event.target.value);
    setErrorMessage('');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const lowercasePasscode = passcode.toLowerCase();
    if (lowercasePasscode === 'vizion') {
      setErrorMessage('');
      onSelect(scenePasscode?.groupId, scenePasscode?.sceneId);
      handleClose();
    } else {
      setErrorMessage(t('incorrect_passcode'));
    }
  };

  const handleClose = () => {
    setPasscode('');
    setErrorMessage('');
    onClose();
  };

  return (
    <div className="popup-passcode">
      <div className="popup-passcode-content">
        <h1 className="popup-passcode-title">{t('enter_passcode')}</h1>
        <form onSubmit={handleSubmit} className="form-passcode">
          <div className="input-container">
            <input
              type="password"
              value={passcode}
              onChange={handlePasscodeChange}
              placeholder={t('please_enter_your_passcode_here')}
              className="input-passcode"
              ref={(element) => element?.focus?.()}
            />
            <p className={`error-message`}>{errorMessage}</p>
          </div>
          <button
            type="submit"
            className={`access-popup-pc ${passcode === '' ? 'disabled' : ''}`}
            disabled={passcode === ''}
          >
            {t('access')}
          </button>
          <button onClick={handleClose} className="close-popup-pc">
            <CIcon icon={cilX} className="cls-icon" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default PopupScenePasscode;
