import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenPopUp } from 'store/actions';

function ControlRotate() {
  const { isOpenPopUp } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isOpenPopUp) return;
    let timer = null;

    const resetTimer = () => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        dispatch(setOpenPopUp(false));
      }, 120000);
    };
    resetTimer();
    window.addEventListener('mousemove', resetTimer);

    return () => {
      window.removeEventListener('mousemove', resetTimer);
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isOpenPopUp, dispatch]);

  return <></>;
}

export default ControlRotate;
