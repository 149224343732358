import styled from 'styled-components';

// Background components
export const ModalBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// boundary for modal
export const ModalBoundary = styled.div`
  width: ${({ modalwidth }) =>
    modalwidth &&
    modalwidth}px; /* the width boundary must be as same as every modal slide*/
  height: ${({ modalheight }) => modalheight && modalheight}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
  /* the max width must be as same as the width of modal ModalBoundary*/
  @media screen and (max-width: 300px) {
    & {
      width: 100vw;
      height: 100vh;
    }
  }
`;

//Container that contains all the modal pages
//This container contains every slide you gonna use in your modal
export const ModalPagesContainer = styled.div`
  min-width: ${({ modalwidth }) =>
    modalwidth &&
    modalwidth}px; /* The width must be total width of all the slide you gonna use */
  height: ${({ modalheight }) => modalheight && modalheight}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  /*here is how to control carousel movement*/
  margin: 0px 0px 0px -${({ marginleft }) => (marginleft ? marginleft : '0')}px; /*this how we control the place of the modal*/
  transition: margin 1s; /*this how you control the animation of carousel when it's changing steps */
  overflow: auto;
`;

//Modal slides
export const ModalSlide = styled.div`
  width: ${({ width }) => width && width}px;
  height: ${({ height }) =>
    height &&
    height}px; /* in this scenario the total height of slide must be as same as modal height*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

// modal buttons container
export const ButtonContainer = styled.span`
  margin-top: 10px;
  display: flex;
  position: absolute;
  display: flex;
  justify-content: ${({ currentpage }) => {
    return currentpage === 0 ? 'flex-end' : 'space-between';
  }};
  width: 100%;
`;

//modal Buttons
export const ModalButton = styled.button`
  background-color: violet-blue;
  border-radius: 15;
  margin: 0 20px;
  padding: 10px 20px;
  font-size: 60px;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const ModalDot = styled.span`
  height: 10px;
  width: 10px;
  background-color: white;
  border-radius: 50%;
  transform: scale(
    ${({ currentpage, index }) => (currentpage === index ? '1.5' : '1')}
  );
  opacity: ${({ currentpage, index }) => (currentpage === index ? '1' : '0.5')};
  transition: all 0.6s ease;
`;
