import React from 'react';
import CIcon from '@coreui/icons-react';
import { cilX } from '@coreui/icons';
import { useTranslation } from 'react-i18next';
import './style.scss';

function CloseButton(props) {
  const { t } = useTranslation();

  return (
    <button className="btn close-btn">
      <CIcon icon={cilX} className="close-icon" />
      <p>{t('close')}</p>
    </button>
  );
}

export default CloseButton;
