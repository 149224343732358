import React from 'react';
import './popup.youtube.scss';

const getVideoId = (videoUrl) => {
  if (!videoUrl) {
    return 'false';
  }
  // eslint-disable-next-line
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#]*).*/;
  const match = videoUrl.match(regExp);
  return match && match[7].length === 11 ? match[7] : 'false';
};

const getYouTubeEmbeddedIframe = (videoId) => `
  <iframe src="https://www.youtube.com/embed/${videoId}?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
`;

const getHtml = (videoUrl) => ({
  __html: getYouTubeEmbeddedIframe(getVideoId(videoUrl)),
});

function PopupYoutube(props) {
  return (
    <div className={`Popup-content Popup-content-youtube`}>
      <div className="Popup-content-wp">
        <div
          className="html-embeded-content"
          dangerouslySetInnerHTML={getHtml(props.url)}
        ></div>
      </div>
    </div>
  );
}

export default PopupYoutube;
