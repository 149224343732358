import React from 'react';

function PopupVimeo({ title, videoId }) {
  return (
    <iframe
      title={title}
      src={`https://player.vimeo.com/video/${videoId}`}
      width="100%"
      height="100%"
      frameBorder="0"
      allow="autoplay; fullscreen"
      allowFullScreen
    />
  );
}

export default PopupVimeo;
