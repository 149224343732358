import React from 'react';
import './popup.iframe.scss';

const getSketchFabUrl = (sketchFabLink) => {
  const clearSpace = sketchFabLink.split(' ');
  const getUrl = clearSpace.filter((item) => item.includes('src'));
  const url = getUrl[0].split('/');
  return url[url.length - 2];
};

const getSketchfabIframe = (url) =>
  `<iframe allow="autoplay; fullscreen; xr-spatial-tracking"  src="https://sketchfab.com/models/${url}/embed"></iframe>;{' '}`;

const getHtml = (url) => ({
  __html: getSketchfabIframe(url),
});

function PopupSketchFab({ media }) {
  const url = getSketchFabUrl(media.sketchFab.url);
  const embedHtml = getHtml(url);

  return (
    <div className={`Popup-content Popup-content-iframe`}>
      <div className="Popup-content-wp">
        <div
          className="html-embeded-content"
          dangerouslySetInnerHTML={embedHtml}
        ></div>
      </div>
    </div>
  );
}

export default PopupSketchFab;
