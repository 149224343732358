import React, { useMemo } from 'react';

import { connect, useSelector } from 'react-redux';
import { isArray } from 'lodash';

import { ensureSubscriptionData } from 'utils';
import MenuV2 from 'containers/menuV2';
import { TitleFollowCursor } from 'components/TitleFollowCursor';
import Modal from 'components/Modal';

import PopupScenePasscode from './popupScenePasscode';
import PopupHandle from './popupHandle';
import AmbientAudio from '../audio';
import PoweredBy from '../../components/powered';
import HandleAutoRotate from './handleAutoRotate';

import './style.scss';

function getAllScenes(data) {
  // Tạo một array mới để chứa tất cả các scenes
  const allScenes = [];

  // Duyệt qua từng item trong array đầu vào
  data?.forEach((item) => {
    // Kiểm tra nếu item có key scenes và đó là một array
    if (item.scenes && Array.isArray(item.scenes)) {
      // Thêm tất cả các scenes của item vào array allScenes
      allScenes.push(...item.scenes);
    }
  });

  // Trả về array chứa tất cả các scenes
  return allScenes;
}

const World2D = (props) => {
  const {
    json,
    tour,
    currentPano,
    autoRotate,
    showSpinner,
    showIntro,
    hotspotHistory,
    introStatus,
    shouldRotate,
    selectSceneById,
    isPasscodeTagOpen,
    setPasscodeTagOpen,
    setScenePasscode,
  } = props;

  const { viewMode, worldParams } = useSelector((state) => state);
  const location = useMemo(() => {
    const { groupId } = worldParams;
    if (isArray(json.groups) && json.groups.length > 0 && groupId) {
      const currentGroup = json.groups.find((g) => g.id === groupId) || {};
      return currentGroup.location || '';
    }
    return tour.location;
  }, [json.groups, tour.location, worldParams]);

  const listMenuScene = useMemo(
    () => props.json.menu && getAllScenes(props.json.menu),
    // eslint-disable-next-line
    []
  );
  const handlePasscodeTagClose = () => {
    setPasscodeTagOpen(false);
  };

  const onSelect = (gId, id) => props.selectSceneById(gId, id);

  function moveItemToTop(arr, targetId) {
    arr.sort((a, b) => b.objects.length - a.objects.length);

    const index = arr.findIndex((item) => {
      return item.objects.some((obj) => obj.id === targetId);
    });

    if (index !== -1) {
      const itemToMove = arr.splice(index, 1)[0];
      arr.unshift(itemToMove);
    }

    return arr;
  }

  const floorMaps = useMemo(() => {
    const mapFloorPlansSet = new Set();
    props?.currentPano?.hotspots.forEach((item) => {
      const floorPlans =
        item?.media?.type === 'MapFloorPlan'
          ? item.media.mapFloorPlan
          : item?.type === 'MapFloorPlan'
          ? item.mapFloorPlan
          : null;
      if (floorPlans) {
        floorPlans.forEach((floorPlan) => {
          mapFloorPlansSet.add(floorPlan);
        });
      }
    });

    return moveItemToTop(Array.from(mapFloorPlansSet), props.currentPano?._id);
  }, [props.currentPano]);

  const isShowMapFloorPlan = useMemo(
    () => !!floorMaps.length || props?.currentPano?.isShowMapFloorPlan,
    [floorMaps.length, props?.currentPano?.isShowMapFloorPlan]
  );

  return (
    <div className={`World-2D-Contents ${viewMode || ''}`}>
      <TitleFollowCursor />
      {json.menu && !showIntro && (
        <MenuV2
          {...props}
          menuList={json.menu}
          scenes={json.scenes}
          groups={json.groups}
          currentPano={currentPano}
          autoRotate={autoRotate}
          shouldRotate={shouldRotate}
          showSpinner={showSpinner}
          toggleRotate={props.toggleRotate}
          toggleFeatured={props.toggleFeatured}
          onSelect={props.selectSceneById}
          toggleMap={props.toggleMap}
          subTitle={location}
          control={props.control}
          switchControl={props.switchControl}
          showLanding={props.onClickPoweredBy}
          setScenePasscode={setScenePasscode}
          setPasscodeTagOpen={setPasscodeTagOpen}
          floorMaps={floorMaps}
          isShowMapFloorPlan={isShowMapFloorPlan}
        />
      )}

      <PoweredBy
        introStatus={introStatus}
        tour={json.tour}
        onSelect={props.selectSceneById}
        scenes={json.scenes}
      />

      <HandleAutoRotate
        autoRotate={autoRotate}
        shouldRotate={shouldRotate}
        scenes={props.json.menu.length ? listMenuScene : json.scenes}
        currentPano={currentPano}
        onChangeScene={props.selectSceneById}
      />

      <PopupHandle
        mediasList={json.media}
        mediaGroups={json.mediaGroups}
        menuList={json.menu}
        tour={json.tour}
        scenes={json.scenes}
        hotspotHistory={hotspotHistory}
        history={props.history}
        currentPano={currentPano}
        selectSceneById={selectSceneById}
        floorMaps={floorMaps}
        onSelect={onSelect}
      />
      {currentPano && (
        <AmbientAudio
          scene={currentPano}
          spinnerOn={showSpinner}
          introOn={showIntro}
        />
      )}

      {isPasscodeTagOpen && (
        <Modal
          children={
            <PopupScenePasscode
              onClose={handlePasscodeTagClose}
              onSelect={onSelect}
              {...props}
            />
          }
          modalClassname="popup-pc"
        />
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  json: store.json || {},
  currentHotspot: store.currentHotspot,
  mapPopupOpen: store.menu.isoMetricMap,
  socialPopupOpen: store.menu.social,
  tour: store.json.tour || null,
  scope: ensureSubscriptionData(store.tour.subscriptionPlan.scopes),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(World2D);
