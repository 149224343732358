import React, { useState } from 'react';

import {
  ModalBackground,
  ModalBoundary,
  ModalPagesContainer,
  ModalSlide,
  ModalButton,
  ButtonContainer,
  ModalDot,
} from './styled';
import './style.scss';
import GalleryItem from './GalleryItem';

function PopupGallery(props) {
  const {
    media: { gallery },
  } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const dimensions = {
    width: window.innerWidth,
    height: window.innerHeight * 0.835,
  };
  const numberOfPages = gallery.length;
  const changePage = (next) => {
    if (next) {
      setCurrentPage((pervState) => pervState + 1);
    } else {
      setCurrentPage((pervState) => pervState - 1);
    }
  };
  return (
    <div className="Popup-content Popup-content-gallery">
      <ModalBackground>
        <ModalBoundary
          modalwidth={dimensions.width}
          modalheight={dimensions.height}
        >
          <ModalPagesContainer
            modalwidth={dimensions.width * numberOfPages}
            modalheight={dimensions.height}
            marginleft={dimensions.width * currentPage}
          >
            {gallery.map((item, index) => (
              <ModalSlide
                key={index}
                width={dimensions.width}
                height={dimensions.height}
              >
                <GalleryItem key={index} item={item} {...props} />
              </ModalSlide>
            ))}
          </ModalPagesContainer>
          <div
            style={{
              position: 'absolute',
              width: '150px',
              minWidth: '150px',
              height: '50px',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              bottom: '20px',
              left: 0,
              right: 0,
              margin: 'auto',
            }}
          >
            {gallery.map((item, index) => (
              <ModalDot key={index} currentpage={currentPage} index={index} />
            ))}
          </div>
          <ButtonContainer currentpage={currentPage}>
            {currentPage > 0 && (
              <ModalButton onClick={() => changePage(false)}>
                <div className="arrow-left">&#x276E;</div>
              </ModalButton>
            )}
            {currentPage < gallery.length - 1 && (
              <ModalButton onClick={() => changePage(true)}>
                <div className="arrow-right">&#10095;</div>
              </ModalButton>
            )}
          </ButtonContainer>
        </ModalBoundary>
      </ModalBackground>
    </div>
  );
}

export default PopupGallery;
