import React from 'react';

export const FullScreenIcon = ({ fullScreen, activeColor }) => {
  return fullScreen ? (
    <svg
      id="icon-expand"
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_14250"
            data-name="Rectangle 14250"
            width="36"
            height="36"
            transform="translate(1842 42)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g
        id="Mask_Group_68"
        data-name="Mask Group 68"
        transform="translate(-1842 -42)"
      >
        <g
          id="Group_4003"
          data-name="Group 4003"
          transform="translate(1844 43.542)"
        >
          <path
            id="Path_1543"
            data-name="Path 1543"
            d="M10.144,3.458h3V14.1H2.5v-3h7.644Z"
            fill={activeColor}
          />
          <path
            id="Path_1544"
            data-name="Path 1544"
            d="M2.5,19.815H13.144V30.458h-3V22.815H2.5Z"
            fill={activeColor}
          />
          <path
            id="Path_1545"
            data-name="Path 1545"
            d="M18.856,3.458h3V11.1H29.5v3H18.856Z"
            fill={activeColor}
          />
          <path
            id="Path_1546"
            data-name="Path 1546"
            d="M18.857,19.815H29.5v3H21.857v7.643h-3Z"
            fill={activeColor}
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg
      id="icon-expand"
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_14250"
            data-name="Rectangle 14250"
            width="36"
            height="36"
            transform="translate(1842 42)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g
        id="Mask_Group_68"
        data-name="Mask Group 68"
        transform="translate(-1842 -42)"
      >
        <g id="Group_4000" data-name="Group 4000" transform="translate(0 0.5)">
          <path
            id="Path_1543"
            data-name="Path 1543"
            d="M5.5,14.1h-3V3.458H13.144v3H5.5Z"
            transform="translate(1844 43.042)"
            fill="#fff"
          />
          <path
            id="Path_1544"
            data-name="Path 1544"
            d="M13.143,30.458H2.5V19.815h3v7.643h7.643Z"
            transform="translate(1844 43.042)"
            fill="#fff"
          />
          <path
            id="Path_1545"
            data-name="Path 1545"
            d="M29.5,14.1h-3V6.458H18.856v-3H29.5Z"
            transform="translate(1844 43.042)"
            fill="#fff"
          />
          <path
            id="Path_1546"
            data-name="Path 1546"
            d="M29.5,30.458H18.857v-3H26.5V19.815h3Z"
            transform="translate(1844 43.042)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
