import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import Color from 'color';

import { defaultIconBgColor } from 'consts';
import i18n from 'utils/i18next';
import IconAerial from 'components/icons/SceneIconAerial';
import IconGround from 'components/icons/SceneIconGround';

import './style.scss';
import { getLanguageForKey } from 'utils/languageHelper';

function NavMenuItem(props) {
  const currentLanguage = i18n.language;
  const {
    item,
    index,
    active,
    onSelect,
    viewed,
    color,
    setScenePasscode,
    setPasscodeTagOpen,
  } = props;
  const onClickMenuItem = () => {
    if (item.hasPasscode) {
      setPasscodeTagOpen(true);
      setScenePasscode(item.groupId, item.id);
    } else {
      return onSelect && onSelect();
    }
  };
  const colorChecked = useMemo(
    () => (viewed ? color : defaultIconBgColor),
    [viewed, color]
  );

  const colorActive = useMemo(() => {
    if (!active) return '';
    const clrString = Color(color).alpha(0.4);
    return clrString.toString();
  }, [active, color]);

  return (
    <div
      className={`Pan-Menu-item available ${active ? 'active' : ''}`}
      style={{ backgroundColor: colorActive }}
      onClickCapture={onClickMenuItem}
    >
      <div className="Pan-Menu-group">
        <div className="Pan-Menu-group">
          <span className={`Pan-Menu-item-type`}>
            {item.type === 'AERIAL' ? (
              <IconAerial colorChecked={colorChecked} active={active} />
            ) : (
              <IconGround colorChecked={colorChecked} active={active} />
            )}
          </span>
          <span className="Pan-Menu-item-title">{`${index} . ${getLanguageForKey(
            item,
            'title',
            currentLanguage
          )}`}</span>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(NavMenuItem);
