import React from 'react';
// import { DoubleSide } from 'three';

import { useVideoTexture } from '@react-three/drei';

function VideoSphere() {
  // eslint-disable-next-line
  const texture = useVideoTexture(
    'https://dexf5198s0gy5.cloudfront.net/videos/packaging-area%202-video.mp4?chunk=2000000'
  );
  return (
    <>
      {/* <sphereGeometry args={[1000, 64, 32]} />
      <meshBasicMaterial toneMapped={false} side={DoubleSide} map={texture} /> */}
    </>
  );
}

export default VideoSphere;
