import React, { useMemo } from 'react';
import { LAYOUT_UI } from 'consts/ui.const';

import PoweredLogo from './logo';
import PoweredLogoV2 from './logov2';
import HomeIcon from './aacorporation-home-icon.png';

import './style.scss';

function PoweredBy(props) {
  const { introStatus, tour, scenes } = props;

  const defaultScene = useMemo(
    () => scenes.find((scene) => scene.isDefault),
    [scenes]
  );

  const isSchoolLayout = useMemo(
    () => tour.menuStyle === LAYOUT_UI.SCHOOL,
    [tour]
  );

  const isAACorporation = useMemo(
    () => tour.menuStyle === LAYOUT_UI.AACORPORATION,
    [tour]
  );

  return (
    <div
      className={`powered-container intro-${introStatus} ${
        isSchoolLayout ? 'powered-container_school-layout' : ''
      } ${isAACorporation ? 'powered-container_aacorp' : ''}`}
    >
      <div
        onClick={() => props.onSelect(defaultScene.groupId, defaultScene.id)}
        className={`${isAACorporation ? 'powered-container__aacorp-img' : ''}`}
      >
        {isAACorporation && (
          <img
            className="powered-container__aacorp-home-icon"
            src={HomeIcon}
            alt="aacorp-home-icon"
          />
        )}
        {!isAACorporation &&
          (!isSchoolLayout ? <PoweredLogo /> : <PoweredLogoV2 />)}
      </div>
    </div>
  );
}

export default PoweredBy;
