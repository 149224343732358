import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { canFullscreen, toggleFullScreen } from 'utils';
import configs from 'configs';

import { useNavigatedRoutes } from 'common/hooks';
import {
  AutoRotateIcon,
  ContactFormIcon,
  FullScreenIcon,
  AudioIcon,
  MapIcon,
  FloorMapIcon,
  PinIcon,
  HelpIcon,
  MapFloorPlanIcon,
  ShareIcon,
  // ExpandIcon,
} from 'components/icons';
import PanoMenu from 'components/panoMenu';
import { limitLetters } from 'utils/limitLetters';
import { AutoPlayIcon } from 'components/icons/AutoPlayIcon';
import { setAutoPlay } from 'store/actions';
import GalleryMenu from 'components/GalleryMenu';
import { LAYOUT_UI } from 'consts/ui.const';
import SchoolLayoutMenu from 'components/SchoolLayoutMenu';

import SchoolLayoutIcons from './SchoolLayoutIcons';
import i18n from 'utils/i18next';
import { getLanguageForKey } from 'utils/languageHelper';
import MinorSector from 'components/MapViewAngle/MinorSector';
import AACorporationLayout from 'components/AACorporationLayout';

const MenuForDesktop = (props) => {
  const {
    menuList,
    isNavOpen,
    menuPosition,
    currentPano,
    subTitle,
    menuOpenScene,
    toggleRotate,
    toggleMenu,
    shouldRotate,
    featuredMedia,
    color,
    isShowOnMap,
    isShowAudioIcon,
    isShowMapFloorPlan,
    floorMaps,
    tour,
    showLanding,
    groups,
    onSelect,
    scenes,
    openMenu,
    setOpenMenu,
    setScenePasscode,
    setPasscodeTagOpen,
    isAACorporation,
  } = props;

  const [handleUpdateRoute] = useNavigatedRoutes();
  const [fullScreen, setFullScreen] = useState(false);

  const { autoPlayMode } = useSelector((state) => state);
  const [isAutoPlay, setIsAutoPlay] = useState(autoPlayMode);
  const [isActiveMap, setIsActiveMap] = useState(false);
  const currentLanguage = i18n.language;

  const [openDropDown, setOpenDropDown] = useState(false);
  const [mapShowed, setMapShowed] = useState();

  useEffect(() => {
    setOpenDropDown(false);
  }, [currentPano]);

  const isMenuGallery = useSelector(
    ({ searchParams }) => searchParams['menu-ui'] === 'gallery'
  );

  const shouldShowMapIcon = useMemo(
    () =>
      tour.mapCoordinates.length > 0 &&
      !(tour.mapCoordinates[0] === 0 && tour.mapCoordinates[1] === 0),
    [tour]
  );

  const isSchoolLayout = useMemo(
    () => tour.menuStyle === LAYOUT_UI.SCHOOL,
    [tour]
  );

  const toggleHelpButton = () => {
    showLanding();
  };

  const handleFullScreen = () => {
    toggleFullScreen();
    setFullScreen(!fullScreen);
  };

  const dispatch = useDispatch();

  const switchMode = useCallback(() => {
    setIsAutoPlay(!isAutoPlay);
    dispatch(setAutoPlay(!isAutoPlay));
  }, [dispatch, isAutoPlay]);

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    if (floorMaps.length > 0 && currentPano) {
      const getCurrentMap = (floorMaps, pano) => {
        return floorMaps.find((item) =>
          item.objects.length
            ? item.objects.some((obj) => obj.id === pano._id)
            : item
        );
      };

      const intialMap = getCurrentMap(floorMaps, currentPano);

      setMapShowed(intialMap);
    }
  }, [currentPano, floorMaps]);

  // const mediaMap2D = useMemo(
  //   () =>
  //     currentPano?.hotspots
  //       .map((hs) =>
  //         hs.media?.type === 'MapFloorPlan' && hs.media.mapFloorPlan.length
  //           ? hs.media
  //           : null
  //       )
  //       .filter((el) => el !== null)[0] || null,
  //   [currentPano]
  // );

  const handleSelectMap = (item) => {
    setMapShowed(item);
  };

  return (
    <div
      className={`menuV2-desktop--container ${isAACorporation ? 'aacorp' : ''}`}
    >
      {isMenuGallery && (
        <GalleryMenu
          scenes={scenes}
          desktop={true}
          groups={groups}
          onSelect={onSelect}
          activeColor={color}
          currentPano={currentPano}
          openMenu={openMenu}
          handleOpenMenu={handleOpenMenu}
        />
      )}

      {isSchoolLayout && (
        <SchoolLayoutMenu
          menuList={menuList}
          scenes={scenes}
          desktop={true}
          groups={groups}
          onSelect={onSelect}
          activeColor={color}
          currentPano={currentPano}
          openMenu={openMenu}
          handleOpenMenu={handleOpenMenu}
          isSchoolLayout={isSchoolLayout}
        />
      )}

      {isAACorporation && (
        <AACorporationLayout
          menuList={menuList}
          scenes={scenes}
          desktop={true}
          groups={groups}
          onSelect={onSelect}
          activeColor={color}
          currentPano={currentPano}
          openMenu={openMenu}
          handleOpenMenu={handleOpenMenu}
          isSchoolLayout={isSchoolLayout}
        />
      )}
      <div
        className={`menuV2-desktop--container_left ${
          isMenuGallery
            ? `container-width ${!openMenu && 'container-width-closed'}`
            : ''
        }`}
      >
        <div>
          {!isMenuGallery && !isSchoolLayout && !isAACorporation && (
            <div
              className="header"
              onClick={() => toggleMenu(tour.isPublic ? false : !isNavOpen)}
              style={{ cursor: ` ${tour.isPublic ? 'default' : 'pointer'}` }}
            >
              <div className="header_icon">
                <PinIcon color={color} />
              </div>
              <div className="header_titles">
                <div className="header_titles_title" style={{ color }}>
                  {currentPano
                    ? limitLetters(
                        getLanguageForKey(
                          currentPano,
                          'title',
                          currentLanguage
                        ),
                        100
                      )
                    : null}
                </div>
                <p className="header_titles_label">{subTitle || null}</p>
              </div>
            </div>
          )}
          <PanoMenu
            isOpen={isNavOpen}
            menu={menuList}
            location={menuPosition}
            onClose={() => toggleMenu(false)}
            onSelect={menuOpenScene}
            color={color}
            setScenePasscode={setScenePasscode}
            setPasscodeTagOpen={setPasscodeTagOpen}
          />
        </div>
      </div>
      <div className="menuV2-desktop--container_right">
        <div
          className={`icons_left ${
            isMenuGallery && !openMenu ? 'margin-left__0' : ''
          }`}
          style={{ pointerEvents: 'auto' }}
        >
          {shouldShowMapIcon && !isAACorporation && (
            <div
              className="icon"
              style={{
                display: isShowOnMap ? 'flex' : 'none',
              }}
              onClick={() => handleUpdateRoute(configs.mapRoute)}
            >
              <MapIcon />
            </div>
          )}
        </div>
        {isSchoolLayout ? (
          <SchoolLayoutIcons
            currentPano={currentPano}
            isAutoPlay={isAutoPlay}
            color={color}
            toggleHelpButton={toggleHelpButton}
            featuredMedia={featuredMedia}
            isShowAudioIcon={isShowAudioIcon}
            toggleRotate={toggleRotate}
            shouldRotate={shouldRotate}
            canFullscreen={canFullscreen}
            menuPosition={menuPosition}
            handleFullScreen={handleFullScreen}
            fullScreen={fullScreen}
            isShowMapFloorPlan={isShowMapFloorPlan}
            setIsActiveMap={setIsActiveMap}
            isActiveMap={isActiveMap}
            mapShowed={mapShowed}
            setOpenDropDown={setOpenDropDown}
            openDropDown={openDropDown}
            handleSelectMap={handleSelectMap}
            scenes={scenes}
            onSelect={onSelect}
            switchMode={switchMode}
            handleUpdateRoute={handleUpdateRoute}
            isSchoolLayout={isSchoolLayout}
          />
        ) : (
          <div className="icons_right">
            {shouldShowMapIcon && isAACorporation && (
              <div
                className="icon"
                style={{
                  display: isShowOnMap ? 'flex' : 'none',
                }}
                onClick={() => handleUpdateRoute(configs.mapRoute)}
              >
                <MapIcon />
              </div>
            )}
            <div className="icon" onClick={() => switchMode()}>
              <AutoPlayIcon shouldRotate={isAutoPlay} activeColor={color} />
            </div>
            <div className="icon" onClick={toggleHelpButton}>
              <HelpIcon />
            </div>
            {featuredMedia.map && (
              <div
                className="icon"
                onClick={() => handleUpdateRoute(featuredMedia.map.id)}
              >
                <FloorMapIcon />
              </div>
            )}
            {featuredMedia.contact && (
              <div
                className="icon"
                onClick={() => handleUpdateRoute(featuredMedia.contact.id)}
              >
                <ContactFormIcon />
              </div>
            )}
            {isShowAudioIcon && (
              <AudioIcon activeColor={color} className="icon" />
            )}
            <div
              className="icon"
              onClick={() => {
                toggleRotate();
              }}
            >
              <AutoRotateIcon shouldRotate={shouldRotate} activeColor={color} />
            </div>
            {canFullscreen() && menuPosition !== 'bottom' && (
              <div className="icon" onClick={handleFullScreen}>
                <FullScreenIcon fullScreen={fullScreen} activeColor={color} />
              </div>
            )}
            {isShowMapFloorPlan && (
              <>
                <div
                  className="icon position-relative"
                  onClick={() => setIsActiveMap(!isActiveMap)}
                >
                  <MapFloorPlanIcon active={isActiveMap} />
                </div>
                <div
                  className={`mapfloorplan_container ${
                    isActiveMap ? 'open' : ''
                  }
                  ${
                    isActiveMap && isAACorporation
                      ? 'mapfloorplan_container-aacorp  open-aacorp'
                      : ''
                  }
                  `}
                >
                  {mapShowed && (
                    <>
                      {floorMaps?.length > 1 ? (
                        <div className="map_header">
                          <div
                            className="map_dropdown"
                            onClick={() => setOpenDropDown(!openDropDown)}
                          >
                            <span>
                              {limitLetters(
                                getLanguageForKey(
                                  mapShowed.image,
                                  'name',
                                  currentLanguage
                                ),
                                15
                              )}
                            </span>
                            <i className="map_arrow down"></i>
                            {openDropDown && (
                              <div
                                className={`map_dropdown_options ${
                                  openDropDown ? 'show' : ''
                                }`}
                              >
                                <ul className="dropdown_items">
                                  {floorMaps?.map((item, index) => (
                                    <li
                                      onClick={() => handleSelectMap(item)}
                                      key={index}
                                    >
                                      {limitLetters(item.image.name, 10)}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                          {/* <div
                          className="icon"
                          onClick={() => handleUpdateRoute(mediaMap2D.id)}
                        >
                          <ExpandIcon />
                        </div> */}
                        </div>
                      ) : null}
                      <div className="map_image">
                        <img
                          src={mapShowed.image.url}
                          alt={mapShowed.image.name}
                        />
                        {mapShowed.objects.map((viewpoint) => {
                          const currentPoint = viewpoint.id === currentPano._id;
                          const [left, top] = viewpoint.coordinates;
                          const leftInPercent = left * 100;
                          const topInPercent = top * 100;
                          const currentScene =
                            scenes.find((s) => s._id === viewpoint.id) || {};

                          return (
                            <React.Fragment key={viewpoint.id}>
                              <div
                                className={`viewpoint-container ${
                                  currentPoint
                                    ? 'current-viewpoint'
                                    : 'viewpoint'
                                } `}
                                key={viewpoint.id}
                                style={{
                                  left: `${leftInPercent}%`,
                                  top: `${topInPercent}%`,
                                }}
                                onClick={() =>
                                  onSelect(
                                    currentScene.groupId,
                                    currentScene.id
                                  )
                                }
                              ></div>
                              <p
                                className="scene-title"
                                style={{
                                  left: `${leftInPercent}%`,
                                  top: `${topInPercent}%`,
                                }}
                              >
                                {getLanguageForKey(
                                  currentScene,
                                  'title',
                                  currentLanguage
                                )}
                              </p>
                              <div
                                className="circular-sector"
                                style={{
                                  display: `${currentPoint ? 'block' : 'none'}`,
                                  left: `${leftInPercent}%`,
                                  top: `${topInPercent}%`,
                                }}
                              >
                                <MinorSector {...props} />
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </div>

                      <div
                        className="mapfloorplan_close"
                        onClick={() => setIsActiveMap(false)}
                      >
                        <button className="mapfloorplan_close-btn">
                          &times;
                        </button>
                      </div>
                    </>
                  )}
                </div>

                {isAACorporation && (
                  <div className="tour-icons">
                    <div
                      className="icon-share"
                      onClick={() => handleUpdateRoute(configs.socialRoute)}
                    >
                      <ShareIcon color={color} />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuForDesktop;
