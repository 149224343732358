import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
// import { getLanguageForKey } from 'utils/languageHelper';
import i18n from 'utils/i18next';

window.tourAudioPlayed = false;

const TourAudio = ({ isHaveSoundUrl }) => {
  console.log('🚀 ~ TourAudio ~ isHaveSoundUrl:', isHaveSoundUrl);
  const currentLanguage = i18n.language;
  const audioRef = useRef();
  const { featuredMedia } = useSelector((state) => state.json);
  const enabled = useSelector((state) => state.audio.enabled);
  const autoPlay = useSelector((state) => state.audio.autoPlay);

  useEffect(() => {
    const audioSoundUrl = featuredMedia?.audio?.soundUrl || '';
    if (enabled && audioSoundUrl) {
      audioRef.current.src = audioSoundUrl;
      if (autoPlay) {
        audioRef.current.play();
        window.tourAudioPlayed = true;
      } else {
        audioRef.current.pause();
      }
    } else {
      audioRef.current.src = '';
    }
    if (isHaveSoundUrl) {
      audioRef.current.pause();
    }
  }, [autoPlay, enabled, featuredMedia, currentLanguage, isHaveSoundUrl]);

  return (
    <audio
      ref={audioRef}
      id="tourAudio"
      autoPlay={autoPlay}
      loop={true}
    ></audio>
  );
};

export default TourAudio;
