import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import NavMenuItem from '../menuItem';
import IconArrowUp from '../iconArrow/iconArrowUp';
import IconArrowDown from '../iconArrow/iconArrowDown';
import { getLanguageForKey } from 'utils/languageHelper';
import i18n from 'utils/i18next';

const ListMenuItem = ({
  group,
  toggleMenu,
  onSelect,
  panoId,
  historyLogs,
  color,
  setScenePasscode,
  setPasscodeTagOpen,
}) => {
  const currentLanguage = i18n.language;
  const [toggle, setToggle] = useState(true);
  const [height, setHeight] = useState('auto');

  const onClickMenu = (item) => {
    toggleMenu(false);
    onSelect(item.groupId, item.id);
  };

  let index = 0;

  return (
    <div className="Menu-group">
      <div
        className="Menu-group-title"
        style={{ color: 'white' }}
        onClick={() => {
          setToggle(!toggle);
          setHeight(height === 0 ? 'auto' : 0);
        }}
      >
        <div className="Menu-group-underline">
          <p>{getLanguageForKey(group, 'name', currentLanguage)}</p>
          {toggle ? <IconArrowDown /> : <IconArrowUp />}
        </div>
      </div>
      <AnimateHeight duration={500} height={height}>
        <div className="Menu-group-items">
          {group.scenes.map((item, j) => (
            <NavMenuItem
              key={'Menu-item-' + j}
              index={++index}
              item={item}
              active={panoId === item.id}
              viewed={historyLogs.indexOf(item._id) !== -1}
              onSelect={() => onClickMenu(item)}
              color={color}
              setScenePasscode={setScenePasscode}
              setPasscodeTagOpen={setPasscodeTagOpen}
            />
          ))}
        </div>
      </AnimateHeight>
    </div>
  );
};

export default ListMenuItem;
