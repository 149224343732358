import './Overlay.scss';

export const OverlayComponent = ({
  height,
  width,
  onClick,
  isHover,
  setIsHover,
}) => {
  return (
    <>
      <div
        className={`iframe_overlay ${isHover ? 'hover' : ''}`}
        style={{
          position: 'absolute',
          height: `${height}px`,
          width: `${width}px`,
        }}
        // onMouseMove={(e) => onMouseMove(e)}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={onClick}
      ></div>
    </>
  );
};
