import React from 'react';

const Icon = (props) => {
  return (
    <div className="hotspot_icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="38"
        height="20"
        viewBox="4 4 30 30"
      >
        <defs></defs>
        <rect
          fill="white"
          width="19.324"
          height="14.247"
          rx="2"
          transform="translate(9.323 11.95)"
        />
        <path
          fill="black"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M503.868-1918.123l3.338-4.939,1.845,2.47"
          transform="translate(-490.635 1940.781)"
        />
        <path
          fill="black"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M597.867-1970.719l4.216-7.342,4.436,7.342"
          transform="translate(-580.507 1993.377)"
        />
        <ellipse
          fill="black"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          cx="1.321"
          cy="1.315"
          rx="1.321"
          ry="1.315"
          transform="translate(12.221 14.001)"
        />
      </svg>
    </div>
  );
};

export default Icon;
