import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HotspotIcon from 'containers/hotspots/icons';
import { isMobile } from 'react-device-detect';

const SCENE_ICON_SIZE = 30;

const ScenesDragged = (props) => {
  const {
    item,
    imgRoot,
    scenes,
    hotspotHistory,
    tour,
    selectSceneById,
    currentPoint,
  } = props;
  const [hovering, setHovering] = useState(false);
  const { tourId, groupId, sceneId } = useSelector(
    (state) => state.worldParams
  );
  const history = useHistory();

  const currentScence = useMemo(() => {
    return scenes.find((s) => s._id === item.id) || {};
  }, [item.id, scenes]);

  const isActiveIcon = currentScence && currentScence.id === sceneId;

  const navigateScene = () => {
    history.push(`/${tourId}/${groupId}/${currentScence.id}`);
  };

  const visited = useMemo(() => {
    return hotspotHistory.includes(item.id);
  }, [hotspotHistory, item.id]);

  const handleChangeScene = () => {
    selectSceneById(currentScence.groupId, currentScence.id);
  };

  return (
    <div
      className={`position-absolute badge-scene viewpoint-container ${
        currentPoint ? 'current-viewpoint' : 'viewpoint'
      }`}
      style={{
        left: `${item.coordinates[0] * 100}%`,
        top: `${item.coordinates[1] * 100}%`,
        width: isMobile ? 15 : SCENE_ICON_SIZE,
        height: isMobile ? 15 : SCENE_ICON_SIZE,
      }}
      onClick={handleChangeScene}
    >
      <p className="scene-title">{currentScence.title}</p>
      <HotspotIcon
        type={item.type}
        tour={tour}
        isActiveIcon={isActiveIcon}
        onClick={navigateScene}
        scene={currentScence}
        previewImgUrl={hovering ? imgRoot.url : null}
        isMapFloorPlan
        isHover={hovering}
        index={item.index + 1}
        hoverIn={() => {
          setHovering(true);
        }}
        hoverOut={() => {
          setHovering(false);
        }}
        visited={visited}
        color={'red' || ''}
      />
    </div>
  );
};

export default ScenesDragged;
