import { useFrame, useThree } from '@react-three/fiber';
import React, {
  useRef,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import * as THREE from 'three';

import { ShapeGeometry, MeshStandardMaterial, Vector2 } from 'three';
import ShapeHover from './ShapeHover';
const SPLANE_DISTANCE = 250;

const updateDimension = (camera, size) => {
  const FOV_Radians = camera.fov * (Math.PI / 180);
  // Calculate the adjusted FOV considering the zoom factor
  const Adjusted_FOV_Radians = FOV_Radians / camera.zoom;

  const aspectRatio = size.width / size.height;

  const height = 2 * SPLANE_DISTANCE * Math.tan(Adjusted_FOV_Radians / 2);
  const width = height * aspectRatio;

  return { width, height };
};

const MyShape = ({ position, points, onClick, title, id }) => {
  const meshRef = useRef();
  const animRef = useRef(1);

  const { camera, size, raycaster, scene } = useThree();

  const [planeSize, setPlaneSize] = useState(updateDimension(camera, size));

  useEffect(() => {
    const refreshSize = () => {
      const newPlaneSize = updateDimension(camera, size);
      setPlaneSize(newPlaneSize);
    };

    refreshSize();

    window.addEventListener('resize', refreshSize);

    return () => window.removeEventListener('resize', refreshSize);
  }, [camera, size]);

  const geometry = useMemo(() => {
    const newPoints = [...points, points[0]];

    const shape = new THREE.Shape();
    const { width: w, height: h } = planeSize;

    const updatePoint = (x, y) => {
      return {
        x: x * w * 0.5,
        y: y * h * 0.5,
      };
    };
    shape.moveTo(
      updatePoint(...newPoints[0]).x,
      updatePoint(...newPoints[0]).y
    );

    for (let i = 1; i < newPoints.length; i++) {
      const currentPoint = updatePoint(...newPoints[i]);
      shape.lineTo(currentPoint.x, currentPoint.y);
    }
    return new ShapeGeometry(shape);
  }, [points, planeSize]);

  const material = useMemo(() => {
    return new MeshStandardMaterial({
      color: 0xedeb3e,
      transparent: true,
      opacity: 0.6,
    });
  }, []);

  useEffect(() => {
    const lookAtVector = new THREE.Vector3(0, 0, 0);
    meshRef.current.lookAt(lookAtVector);
  }, [meshRef]);

  useFrame((state, delta) => {
    if (animRef.current === 1) {
      const newOpacity = material.opacity + delta;
      if (newOpacity > 0.7) {
        animRef.current = -1;
        material.opacity = 0.7;
      } else {
        material.opacity = newOpacity;
      }
    } else {
      const newOpacity = material.opacity - delta;
      if (newOpacity < 0) {
        animRef.current = 1;
        material.opacity = 0;
      } else {
        material.opacity = newOpacity;
      }
    }
  });

  const [isHover, setIsHover] = useState(false);

  const onClickMesh = useCallback(() => {
    onClick();
    setIsHover(false);
  }, [onClick]);

  const htmlName = useMemo(() => `html-image ${id}`, [id]);

  useEffect(() => {
    const onMouseMove = (event) => {
      const mouse = new Vector2();
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);
      const objects = scene.getObjectByName(htmlName);

      const intersects = raycaster.intersectObjects([objects]);

      if (intersects.length > 0) {
        setIsHover(true);
      } else {
        setIsHover(false);
      }
    };
    document.body.addEventListener('mousemove', onMouseMove);
    return () => document.body.removeEventListener('mousemove', onMouseMove);
  }, [camera, htmlName, raycaster, scene]);

  return (
    <>
      <ShapeHover title={title} isHover={isHover} htmlName={'vizion-polygon'} />
      <mesh
        ref={meshRef}
        name={htmlName}
        position={position}
        geometry={geometry}
        material={material}
        renderOrder={2}
        onClick={onClickMesh}
      />
    </>
  );
};

export const ShapeIndicator = (props) => {
  const { position, points, onClick, title, id } = props;

  return (
    <MyShape
      position={position}
      points={points}
      onClick={onClick}
      title={title}
      id={id}
    />
  );
};
