import React, { useCallback, useMemo, useState } from 'react';

import { connect, useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { isEqual } from 'lodash';

import { menuHighlightDefaultColor } from 'consts';
import configs from 'configs';
import { EVENT_TYPE, trackEventByType } from 'gaTracking';
import { ensureSubscriptionData, switchControlMode } from 'utils';

import { ShareIcon } from 'components/icons';
import { useNavigatedRoutes } from 'common/hooks';

import tourGuilde from './tour-guild-aa.png';

import {
  setMenuPosition,
  toggleAudio,
  toggleMenu,
  togglePanoMode,
  setControlMode,
} from 'store/actions';

import MenuForMobile from 'components/MenuForMobile';
import MenuForDesktop from 'components/MenuForDesktop';
import { LAYOUT_UI } from 'consts/ui.const';
import { isMobile } from 'react-device-detect';

const DEFAUlt_MAP_COORDINATES = [0, 0, 10];

const MenuV2 = (props) => {
  const isPhone = useMediaQuery({ query: '(max-width: 480px)' });
  const { logoURL, hasLogo, isSchoolLayout, isAACorporation } = useSelector(
    (state) => ({
      logoURL: state.logoURL,
      hasLogo: state.hasLogo,
      isSchoolLayout: state.tour.menuStyle === LAYOUT_UI.SCHOOL,
      isAACorporation: state.tour.menuStyle === LAYOUT_UI.AACORPORATION,
    })
  );

  const [openMenu, setOpenMenu] = useState(
    !(
      props.tour.menuStyle === LAYOUT_UI.SCHOOL ||
      props.tour.menuStyle === LAYOUT_UI.AACORPORATION
    )
  );

  const enableBranding = useSelector(
    ({ subscriptionPlan }) => subscriptionPlan.enableBranding
  );
  const menuHighlightColor = props.json.tour.menuHighlightColor;

  const dispatch = useDispatch();
  const [handleUpdateRoute] = useNavigatedRoutes();

  const menuOpenScene = (gId, sId) => {
    props.onSelect(gId, sId);
    trackEventByType(EVENT_TYPE.SCENE_FROM_MENU, sId);
  };
  const { controlMode } = useSelector((state) => state);

  const handleSwitchControlMode = useCallback(async () => {
    const switchToMode = await switchControlMode(controlMode);
    if (switchToMode !== controlMode) dispatch(setControlMode(switchToMode));
  }, [controlMode, dispatch]);

  const isShowOnMap = useMemo(
    () => !isEqual(props.tourMapCoordinates, DEFAUlt_MAP_COORDINATES),
    [props.tourMapCoordinates]
  );

  const hightLightColor = useMemo(
    () => (enableBranding ? menuHighlightColor : menuHighlightDefaultColor),
    [enableBranding, menuHighlightColor]
  );

  const isShowAudioIcon = useMemo(() => props.audio.available, [props.audio]);

  const defaultRotation = useMemo(() => {
    const { floorMaps } = props;
    const currentMap = floorMaps.length
      ? floorMaps[0].objects.find((map) => map.id === props.currentPano._id)
      : null;

    return currentMap?.defaultRotation || 3.1;
  }, [props]);

  return (
    <>
      <div
        className={`tour-infor ${
          props.isNavOpen ? 'tour-infor_navMenuOpen' : ''
        } ${isSchoolLayout ? 'tour-infor_school-layout' : ''}`}
      >
        {enableBranding && hasLogo && (
          <div
            className={`tour-logo ${
              isAACorporation ? 'tour-logo__aacorp' : ''
            }`}
          >
            <img alt="tour-logo" src={logoURL}></img>
          </div>
        )}

        {isAACorporation && !isMobile && (
          <div className={` ${isAACorporation ? 'tour-guild__aacorp' : ''}`}>
            <img alt="tour-logo" src={tourGuilde}></img>
          </div>
        )}

        {!isSchoolLayout && !isAACorporation && (
          <div className="tour-icons">
            <div
              className="icon-share"
              onClick={() => handleUpdateRoute(configs.socialRoute)}
            >
              <ShareIcon color={hightLightColor} />
            </div>
          </div>
        )}
      </div>

      {isPhone ? (
        <MenuForMobile
          menuOpenScene={menuOpenScene}
          handleSwitchControlMode={handleSwitchControlMode}
          color={hightLightColor}
          isShowOnMap={isShowOnMap}
          isShowAudioIcon={isShowAudioIcon}
          floorMaps={props.floorMaps}
          isShowMapFloorPlan={props.isShowMapFloorPlan}
          defaultRotation={defaultRotation}
          isAACorporation={isAACorporation}
          openMenu={openMenu}
          {...props}
        />
      ) : (
        <MenuForDesktop
          menuOpenScene={menuOpenScene}
          handleSwitchControlMode={handleSwitchControlMode}
          color={hightLightColor}
          isShowOnMap={isShowOnMap}
          isShowAudioIcon={isShowAudioIcon}
          floorMaps={props.floorMaps}
          isShowMapFloorPlan={props.isShowMapFloorPlan}
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          defaultRotation={defaultRotation}
          isAACorporation={isAACorporation}
          {...props}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  dimentions,
  menu,
  audio,
  json,
  currentScene,
  tour,
}) => ({
  dimentions,
  isNavOpen: menu.isOpen,
  menuPosition: menu.position,
  mapPopupOpen: menu.isoMetricMap,
  socialPopupOpen: menu.social,
  audio,
  json,
  featured: json.featured,
  featuredMedia: json.featuredMedia,
  tourMapCoordinates: json.tour.mapCoordinates,
  showOnMap: currentScene.showOnMap,
  scope: ensureSubscriptionData(tour.subscriptionPlan.scopes),
  tour,
});

const mapDispatchToProps = {
  toggleMenu,
  togglePanoMode,
  setMenuPosition,
  toggleAudio,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuV2);
