function setFont() {
  const doc = document.documentElement;
  doc.style.setProperty('--secondary-font', '"EB Garamond", serif');
}

export const generateJsonPath = () => {
  setFont();
  return '/assets/duc-thanh-wood/aa-corporation.json';
  // var pathName = window.location.pathname;
  // if (pathName.includes('/duc-thanh-wood')) {
  //   return '/assets/duc-thanh-wood/duc-thanh-wood.json';
  // } else if (pathName.includes('/private-aa-corporation')) {
  //   setFont();
  //   return '/assets/duc-thanh-wood/aa-corporation.json';
  // } else {
  //   return '';
  // }
};
